import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/pages/AdminGeneralSettings.module.css';

const AdminGeneralSettings = () => {
  const [formData, setFormData] = useState({
    systemName: '',
    adminEmails: '',
    telegramLink: '',
    instagramLink: '',
    theme: 'light',
    logoUrl: '', // URL da logo atual
  });
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await axios.get('/api/admin/general-settings', {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setFormData(res.data);
      } catch (err) {
        console.error('Erro ao carregar as configurações', err);
      }
    };
    fetchSettings();
  }, []);

  const handleFileChange = (e) => setLogo(e.target.files[0]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedFormData = { ...formData };
      if (logo) {
        const formDataUpload = new FormData();
        formDataUpload.append('logo', logo);
        const logoRes = await axios.post('/api/admin/upload-logo', formDataUpload, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          },
        });
        updatedFormData.logoUrl = logoRes.data.logoUrl;
      }
      await axios.post('/api/admin/general-settings', updatedFormData, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      alert('Configurações salvas com sucesso!');
    } catch (err) {
      console.error('Erro ao salvar configurações', err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles['form-container']}>
      <h2 className={styles['form-title']}>Configurações Gerais</h2>

      <div className={styles['form-group']}>
        <label className={styles['form-label']}>Nome do Sistema</label>
        <input
          type="text"
          name="systemName"
          value={formData.systemName}
          onChange={handleChange}
          required
          className={styles['form-input']}
        />
      </div>

      <div className={styles['form-group']}>
        <label className={styles['form-label']}>Emails Administrativos (opcional)</label>
        <input
          type="text"
          name="adminEmails"
          value={formData.adminEmails}
          onChange={handleChange}
          placeholder="Separe os emails por vírgula"
          className={styles['form-input']}
        />
      </div>

      <div className={styles['form-group']}>
        <label className={styles['form-label']}>Link do Telegram (opcional)</label>
        <input
          type="text"
          name="telegramLink"
          value={formData.telegramLink}
          onChange={handleChange}
          className={styles['form-input']}
        />
      </div>

      <div className={styles['form-group']}>
        <label className={styles['form-label']}>Link do Instagram (opcional)</label>
        <input
          type="text"
          name="instagramLink"
          value={formData.instagramLink}
          onChange={handleChange}
          className={styles['form-input']}
        />
      </div>

      <div className={styles['logo-upload-group']}>
        <div>
          <label className={styles['form-label']}>Upload da Logo</label>
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            className={styles['file-input']}
          />
        </div>

        {/* Visualização da Logo Atual */}
        {formData.logoUrl && (
          <div className={styles['logo-preview']}>
            <p className={styles['logo-preview-label']}>Logo Atual:</p>
            <img src={formData.logoUrl} alt="Logo Atual" className={styles['logo-image']} />
          </div>
        )}
      </div>

      <div className={styles['form-group']}>
        <label className={styles['form-label']}>Tema do Sistema</label>
        <select
          name="theme"
          value={formData.theme}
          onChange={handleChange}
          className={styles['theme-select']}
        >
          <option value="light">Claro</option>
          <option value="dark">Escuro</option>
        </select>
      </div>

      <button type="submit" className={styles['submit-button']}>Salvar Configurações</button>
    </form>
  );
};

export default AdminGeneralSettings;
