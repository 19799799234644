import axios from 'axios';

const api = axios.create({
  baseURL: 'https://statsfut.com', // ajuste a URL base conforme necessário
});

axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

export default api;