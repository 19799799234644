import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../styles/pages/Deposits.module.css';

const Deposits = () => {
  const [pixes, setPixes] = useState([]); // Atualize o nome para evitar confusão com "deposits"
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const limit = 50;

  useEffect(() => {
    const fetchPixes = async () => {
      try {
        const res = await axios.get(`/api/admin/deposits?page=${page}&search=${search}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setPixes(res.data.pixes || []); // Certifique-se de que a resposta contém os dados
        setTotalPages(Math.ceil((res.data.totalPixes || 0) / limit));
      } catch (err) {
        console.error(err.response ? err.response.data : err.message);
      }
    };

    fetchPixes();
  }, [page, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reinicia para a primeira página ao buscar
  };

  const nextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const previousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  return (
    <div className={styles['deposits-container']}>
      <h1 className={styles['deposits-header']}>Depósitos</h1>

      <div className={styles['search-container']}>
        <input
          type="text"
          placeholder="Pesquisar"
          value={search}
          onChange={handleSearchChange}
          className={styles['search-input']}
        />
      </div>

      <table className={styles['deposits-table']}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Telefone</th>
            <th>Status</th>
            <th>Data e Hora</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {pixes.map((pix) => (
            <tr key={pix._id}>
              <td data-label="Nome">{pix.userId?.name || 'N/A'}</td>
              <td data-label="Email">{pix.userId?.email || 'N/A'}</td>
              <td data-label="Telefone">{pix.userId?.phone || 'N/A'}</td>
              <td data-label="Status">{pix.status}</td>
              <td data-label="Data e Hora">{new Date(pix.generatedAt).toLocaleString()}</td>
              <td data-label="Valor">R$ {(pix.value / 100).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles['pagination']}>
        <button onClick={previousPage} disabled={page === 1}>
          Anterior
        </button>
        <span>Página {page} de {totalPages}</span>
        <button onClick={nextPage} disabled={page === totalPages}>
          Próximo
        </button>
      </div>
    </div>
  );
};

export default Deposits;
