import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../styles/pages/AdminDashboard.module.css';

const AdminDashboard = () => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const res = await axios.get('/api/admin/stats', {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setStats(res.data);
      } catch (err) {
        console.error(err.response.data);
      }
    };
    fetchStats();
  }, []);

  return (
    <div className={styles['dashboard-container']}>
      <h1 className={styles['dashboard-header']}>Dashboard Administrativo</h1>
      
      <div className={styles['stat-card-container']}>
        <div className={styles['stat-card']}>
          <h2>Depósitos</h2>
          <p className={styles['stat-label']}>Últimas 24 horas</p>
          <div className={styles['stat-value']}>R${stats.depositsLast24H}</div>
          <p className={styles['stat-label']}>Últimos 7 dias</p>
          <div className={styles['stat-value']}>R${stats.depositsLast7D}</div>
          <p className={styles['stat-label']}>Último mês</p>
          <div className={styles['stat-value']}>R${stats.depositsLast1M}</div>
          <p className={styles['stat-label']}>Total</p>
          <div className={styles['stat-value']}>R${stats.totalDeposits}</div>
        </div>

        <div className={styles['stat-card']}>
          <h2>Pix</h2>
          <p className={styles['stat-label']}>Pix gerados nas últimas 24h</p>
          <div className={styles['stat-value']}>{stats.pixGenerated24H}</div>
          <p className={styles['stat-label']}>Pix pagos nas últimas 24h</p>
          <div className={styles['stat-value']}>{stats.pixPaid24H}</div>
          <p className={styles['stat-label']}>Pix gerados total</p>
          <div className={styles['stat-value']}>{stats.pixGeneratedTotal}</div>
          <p className={styles['stat-label']}>Pix pagos total</p>
          <div className={styles['stat-value']}>{stats.pixPaidTotal}</div>
        </div>

        <div className={styles['stat-card']}>
          <h2>Cadastros</h2>
          <p className={styles['stat-label']}>Últimas 24 horas</p>
          <div className={styles['stat-value']}>{stats.registrationsLast24H}</div>
          <p className={styles['stat-label']}>Últimos 7 dias</p>
          <div className={styles['stat-value']}>{stats.registrationsLast7D}</div>
          <p className={styles['stat-label']}>Últimos 30 dias</p>
          <div className={styles['stat-value']}>{stats.registrationsLast30D}</div>
          <p className={styles['stat-label']}>Total</p>
          <div className={styles['stat-value']}>{stats.totalRegistrations}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;