import React, { useState } from 'react';
import AdminMenu from './AdminMenu';
import styles from '../styles/components/AdminLayout.module.css';

const AdminLayout = ({ children }) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  return (
    <div className={`${styles['admin-layout']} ${isMenuCollapsed ? styles['menu-collapsed'] : ''}`}>
      <AdminMenu isCollapsed={isMenuCollapsed} onToggle={() => setIsMenuCollapsed(!isMenuCollapsed)} />
      <div className={styles['admin-content']}>
        {children}
      </div>
    </div>
  );
};

export default AdminLayout;