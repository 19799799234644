import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/pages/ForgotPassword.module.css';

Modal.setAppElement('#root');

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
  });
  const [setTemporaryPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post('/api/users/forgot-password', formData);

      setTemporaryPassword(res.data.temporaryPassword);
      setIsModalOpen(true);
    } catch (err) {
      setError(err.response?.data?.msg || 'Erro ao redefinir a senha');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate('/login'); // Redireciona para a página de login
  };

  return (
    <div className={styles['forgot-password-container']}>
      {/* Wrapper para o conteúdo principal */}
      <div className={styles['forgot-password-content']}>
        <form className={styles['forgot-password-form']} onSubmit={onSubmit}>
          <h2>Redefinir Senha</h2>
          {error && <p className={styles['error-message']}>{error}</p>}
          
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={onChange}
            placeholder="Digite seu email"
            required
            className={styles['forgot-password-input']}
          />
          
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={onChange}
            placeholder="Digite seu telefone"
            required
            className={styles['forgot-password-input']}
          />

          <button type="submit" className={styles['forgot-password-button']}>Enviar</button>
        </form>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Senha Temporária"
          overlayClassName={styles['modal-overlay']}
          className={styles['modal-content']}
        >
          <h2>Senha Temporária</h2>
          <p>Sua senha temporária foi enviada para seu email</p>
          <p>Por favor verifique sua caixa de entrada, e altere sua senha na seção "Minha Conta" após o login.</p>
          <button onClick={closeModal} className={styles['modal-button']}>Ok</button>
        </Modal>
      </div>

    </div>
  );
};

export default ForgotPassword;
