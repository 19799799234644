import React, { useContext } from 'react';
import { UserContext } from '../components/contexts/UserContext';
import styles from '../styles/components/Footer.module.css';

const Footer = () => {
  const { logoUrl, systemName } = useContext(UserContext); // Obtendo logo e nome do sistema do contexto

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        {logoUrl && <img src={logoUrl} alt={`${systemName} Logo`} className={styles.footerLogo} />}
        <p>© 2024 {systemName}. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
