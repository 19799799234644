import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import api from '../axiosConfig';
import styles from '../styles/pages/AdminTeamsAndCountries.module.css';

const AdminTeamsAndCountries = () => {
  const [countries, setCountries] = useState([]);
  const [teams, setTeams] = useState([]);
  const [isSyncing, setSyncing] = useState(false); // Flag for syncing state
  const [logs, setLogs] = useState([]); // Store logs for synchronization
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTeamOpen, setModalTeamOpen] = useState(false);
  const [modalLogOpen, setModalLogOpen] = useState(false); // Modal for synchronization logs
  const [newCountryName, setNewCountryName] = useState('');
  const [newTeamName, setNewTeamName] = useState('');
  const [newTeamCountry, setNewTeamCountry] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editTeam, setEditTeam] = useState({ name: '', country: '', badge: '' });
  const [badgeUrl, setBadgeUrl] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [teamsPerPage] = useState(25);

  // Fetch teams and countries on component mount
  useEffect(() => {
    fetchCountries();
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await api.get('/api/admin/teams');
      setTeams(response.data);
    } catch (err) {
      console.error('Erro ao obter times:', err);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get('/api/admin/countries');
      setCountries(response.data);
    } catch (err) {
      console.error('Erro ao obter países:', err);
    }
  };

  // Function for team synchronization with logs
  const handleSyncTeams = async () => {
    setSyncing(true);
    setModalLogOpen(true); // Open the modal to show logs
    setLogs(['Iniciando sincronização...']); // Initial log message

    try {
      const response = await axios.get('/api/admin/sync-teams');
      setLogs(response.data.logs); // Update logs from response
    } catch (error) {
      setLogs(['Erro ao sincronizar times.']);
      console.error('Erro ao sincronizar times:', error);
    } finally {
      setSyncing(false);
    }
  };

  // Function to create a new country
  const handleCreateCountry = async () => {
    try {
      const response = await axios.post('/api/admin/create-country', { name: newCountryName });
      alert('País criado com sucesso!');
      setCountries([...countries, response.data]);
      setModalIsOpen(false);
    } catch (err) {
      console.error('Erro ao criar país:', err);
      alert('Erro ao criar país.');
    }
  };

  const handleCreateTeam = async () => {
    try {
      await axios.post('/api/admin/create-team', {
        name: newTeamName,
        country: newTeamCountry,
        badge: badgeUrl,
      });
      alert('Time criado com sucesso!');
      setModalTeamOpen(false);
      fetchTeams();
    } catch (err) {
      console.error('Erro ao criar time:', err);
      alert('Erro ao criar time.');
    }
  };

  const handleDelete = async (teamId) => {
    try {
      await axios.delete(`/api/admin/delete-team/${teamId}`);
      alert('Time excluído com sucesso!');
      fetchTeams();
    } catch (err) {
      alert('Erro ao excluir time.');
      console.error(err);
    }
  };

  const handleDeleteAllApiTeams = async () => {
    try {
      const response = await axios.delete('/api/admin/delete-all-api-teams');
      alert(response.data.message);
      fetchTeams();
    } catch (err) {
      console.error('Erro ao excluir todos os times com apiId:', err);
      alert('Erro ao excluir times sincronizados da API.');
    }
  };

  const handleEdit = (team) => {
    setEditTeam({ name: team.name, country: team.country, badge: team.badge, _id: team._id });
    setEditModalOpen(true);
  };

  const handleUpdateTeam = async () => {
    try {
      await axios.put(`/api/admin/update-team/${editTeam._id}`, {
        name: editTeam.name,
        country: editTeam.country,
        badge: editTeam.badge,
      });
      alert('Time atualizado com sucesso!');
      setEditModalOpen(false);
      fetchTeams();
    } catch (err) {
      console.error('Erro ao atualizar time:', err);
      alert('Erro ao atualizar time.');
    }
  };

  const filteredTeams = teams.filter(team =>
    team.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    team.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
    team.apiId?.toString().includes(searchQuery)
  );

  const indexOfLastTeam = currentPage * teamsPerPage;
  const indexOfFirstTeam = indexOfLastTeam - teamsPerPage;
  const currentTeams = filteredTeams.slice(indexOfFirstTeam, indexOfLastTeam);

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredTeams.length / teamsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className={styles['page-container']}>
      <h1 className={styles['header']}>Gerenciamento de Times e Países</h1>

      <div className={styles['buttons-container']}>
        <button onClick={handleSyncTeams} className={styles['button']}>Sincronizar Times</button>
        <button onClick={handleDeleteAllApiTeams} className={styles['button']}>Excluir todos os times da API</button>
        <button onClick={() => setModalIsOpen(true)} className={styles['button']}>Criar País</button>
        <button onClick={() => setModalTeamOpen(true)} className={styles['button']}>Criar Time</button>
      </div>

      <input
        type="text"
        placeholder="Pesquisar por nome, país ou ID"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles['search-input']}
      />

      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Nome do Time</th>
            <th>URL do Brasão</th>
            <th>País</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentTeams.map((team) => (
            <tr key={team._id}>
              <td>{team.name}</td>
              <td>
                <img src={team.badge} alt={team.name} className={styles['team-badge']} />
              </td>
              <td>{team.country}</td>
              <td className={styles['table-actions']}>
                {!team.apiId && <button onClick={() => handleDelete(team._id)} className={styles['delete-button']}>Excluir</button>}
                <button onClick={() => handleEdit(team)} className={styles['edit-button']}>Editar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles['pagination']}>
        <button onClick={prevPage} disabled={currentPage === 1} className={styles['pagination-button']}>Anterior</button>
        <button onClick={nextPage} disabled={currentPage >= Math.ceil(filteredTeams.length / teamsPerPage)} className={styles['pagination-button']}>Próximo</button>
      </div>

      {/* Modal for logs */}
      <Modal isOpen={modalLogOpen} onRequestClose={() => setModalLogOpen(false)} className={styles['modal']} overlayClassName={styles['modal-overlay']}>
        <h2 className={styles['modal-title']}>Logs de Sincronização</h2>
        {isSyncing ? (
          <p>Sincronizando, por favor, aguarde...</p>
        ) : (
          <div className={styles['log-container']}>
            {logs.map((log, index) => (
              <p key={index}>{log}</p>
            ))}
          </div>
        )}
        <button onClick={() => setModalLogOpen(false)} className={styles['modal-cancel-button']}>Fechar</button>
      </Modal>

      {/* Modais */}
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className={styles['modal']} overlayClassName={styles['modal-overlay']}>
        <h2 className={styles['modal-title']}>Criar País</h2>
        <input
          type="text"
          placeholder="Nome do País"
          value={newCountryName}
          onChange={(e) => setNewCountryName(e.target.value)}
          className={styles['modal-input']}
        />
        <div className={styles['modal-actions']}>
          <button onClick={handleCreateCountry} className={styles['modal-save-button']}>Criar</button>
          <button onClick={() => setModalIsOpen(false)} className={styles['modal-cancel-button']}>Cancelar</button>
        </div>
      </Modal>

      <Modal isOpen={modalTeamOpen} onRequestClose={() => setModalTeamOpen(false)} className={styles['modal']} overlayClassName={styles['modal-overlay']}>
        <h2 className={styles['modal-title']}>Criar Time</h2>
        <input
          type="text"
          placeholder="Nome do Time"
          value={newTeamName}
          onChange={(e) => setNewTeamName(e.target.value)}
          className={styles['modal-input']}
        />
        <select
          value={newTeamCountry}
          onChange={(e) => setNewTeamCountry(e.target.value)}
          className={styles['modal-select']}
        >
          <option value="">Selecione um país</option>
          {countries.map((country) => (
            <option key={country._id} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="URL do Brasão"
          value={badgeUrl}
          onChange={(e) => setBadgeUrl(e.target.value)}
          className={styles['modal-input']}
        />
        <div className={styles['modal-actions']}>
          <button onClick={handleCreateTeam} className={styles['modal-save-button']}>Criar</button>
          <button onClick={() => setModalTeamOpen(false)} className={styles['modal-cancel-button']}>Cancelar</button>
        </div>
      </Modal>

      <Modal isOpen={editModalOpen} onRequestClose={() => setEditModalOpen(false)} className={styles['modal']} overlayClassName={styles['modal-overlay']}>
        <h2 className={styles['modal-title']}>Editar Time</h2>
        <input
          type="text"
          placeholder="Nome do Time"
          value={editTeam.name}
          onChange={(e) => setEditTeam({ ...editTeam, name: e.target.value })}
          className={styles['modal-input']}
        />
        <select
          value={editTeam.country}
          onChange={(e) => setEditTeam({ ...editTeam, country: e.target.value })}
          className={styles['modal-select']}
        >
          <option value="">Selecione um país</option>
          {countries.map((country) => (
            <option key={country._id} value={country.name}>
              {country.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="URL do Brasão"
          value={editTeam.badge}
          onChange={(e) => setEditTeam({ ...editTeam, badge: e.target.value })}
          className={styles['modal-input']}
        />
        <div className={styles['modal-actions']}>
          <button onClick={handleUpdateTeam} className={styles['modal-save-button']}>Salvar</button>
          <button onClick={() => setEditModalOpen(false)} className={styles['modal-cancel-button']}>Cancelar</button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminTeamsAndCountries;