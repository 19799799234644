import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import UserPayment from './components/UserPayment';
import AdminDashboard from './components/AdminDashboard';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import AdminLogin from './components/AdminLogin';
import Users from './components/Users';
import AddUser from './components/AddUser';
import EditUser from './components/EditUser';
import Deposits from './components/Deposits';
import AdminPaymentSettings from './components/AdminPaymentSettings'; 
import Admins from './components/Admins';
import EditAdmin from './components/EditAdmin';
import AddAdmin from './components/AddAdmin';
import AdminGeneralSettings from './components/AdminGeneralSettings';
import AdminGames from './components/AdminGames';
import AdminTeamsAndCountries from './components/AdminTeamsAndCountries';
import AdminLayout from './components/AdminLayout'
import ForgotPassword from './components/ForgotPassword'
import UserAccount from './components/UserAccount'
import GameOldDashboard from './components/GameOldDashboard'
import { UserProvider } from './components/contexts/UserContext';


//


function App() {
  return (
    <Router>
    <UserProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<><Navbar /><Home /></>} />
          <Route path="/login" element={<><Navbar /><Login /></>} />
          <Route path="/register" element={<><Navbar /><Register /></>} />
          <Route path="/pagamento" element={<><Navbar /><UserPayment /></>} />
          <Route path="/redefinirSenha" element={<><Navbar /><ForgotPassword /></>} />
          <Route path="/minhaconta" element={<><Navbar /><UserAccount /></>} />
          <Route path="/dashboard"  element={<Dashboard />} />
          <Route path="/jogos-anteriores"  element={<GameOldDashboard/>} />
          

          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={<AdminLayout><AdminDashboard /></AdminLayout>} />
          <Route path="/admin/users" element={<AdminLayout><Users /></AdminLayout>} />
          <Route path="/admin/add-user" element={<AdminLayout><AddUser /></AdminLayout>} />
          <Route path="/admin/edit-user/:id" element={<AdminLayout><EditUser /></AdminLayout>} />
          <Route path="/admin/deposits" element={<AdminLayout><Deposits /></AdminLayout>} />
          <Route path="/admin/payment-settings" element={<AdminLayout><AdminPaymentSettings /></AdminLayout>} />
          <Route path="/admin/admins" element={<AdminLayout><Admins /></AdminLayout>} />
          <Route path="/admin/edit-admin/:id" element={<AdminLayout><EditAdmin /></AdminLayout>} />
          <Route path="/admin/add-admin" element={<AdminLayout><AddAdmin /></AdminLayout>} />
          <Route path="/admin/settings" element={<AdminLayout><AdminGeneralSettings /></AdminLayout>} />
          <Route path="/admin/games" element={<AdminLayout><AdminGames /></AdminLayout>} />
          <Route path="/admin/teamsandcoutries" element={<AdminLayout><AdminTeamsAndCountries /></AdminLayout>} />
        </Routes>
      </div>
    </UserProvider>
    </Router>
  );
}

export default App;