import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/pages/Admins.module.css';

const AdminList = () => {
  const [admins, setAdmins] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const res = await axios.get('/api/admin/admins', {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setAdmins(res.data);
      } catch (err) {
        console.error(err.response.data);
      }
    };
    fetchAdmins();
  }, []);

  const handleEdit = (id) => {
    navigate(`/admin/edit-admin/${id}`);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Tem certeza que deseja remover este administrador?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`/api/admin/admins/${id}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setAdmins(admins.filter(admin => admin._id !== id));
    } catch (err) {
      console.error(err.response.data);
    }
  };

  const handleAddAdmin = () => {
    navigate('/admin/add-admin');
  };

  return (
    <div className={styles['page-container']}>
      <h1 className={styles['page-title']}>Administradores</h1>
      <button onClick={handleAddAdmin} className={styles['add-button']}>Adicionar Administrador</button>
      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {admins.map(admin => (
            <tr key={admin._id}>
              <td>{admin.name}</td>
              <td>{admin.email}</td>
              <td className={styles['table-actions']}>
                <button onClick={() => handleEdit(admin._id)} className={`${styles['action-button']} ${styles['edit-button']}`}>Editar</button>
                <button onClick={() => handleDelete(admin._id)} className={`${styles['action-button']} ${styles['delete-button']}`}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminList;
