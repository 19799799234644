import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/pages/AdminUsers.module.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState({
    totalUsers: 0,
    paidUsers: 0,
    unpaidUsers: 0,
  });
  const navigate = useNavigate();
  
  const handleEdit = (id) => {
    navigate(`/admin/edit-user/${id}`);
  };
  
  useEffect(() => {
    // Carregar os usuários
    const fetchUsers = async () => {
      try {
        const res = await axios.get('/api/admin/users', {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setUsers(res.data);

        // Atualizando o dashboard de usuários
        const paidUsers = res.data.filter(user => user.isPaidUser).length;
        const unpaidUsers = res.data.length - paidUsers;
        setStats({
          totalUsers: res.data.length,
          paidUsers,
          unpaidUsers,
        });
      } catch (err) {
        console.error(err.response.data);
      }
    };

    fetchUsers();
  }, []);

  // Função para remover usuário
  const removeUser = async (id) => {
    const confirmDelete = window.confirm('Tem certeza que deseja remover este usuário?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`/api/admin/users/${id}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setUsers(users.filter(user => user._id !== id)); // Atualiza a lista após a remoção
    } catch (err) {
      console.error(err.response.data);
    }
  };
  // Função para redirecionar para a página de adicionar novo usuário
  const addUser = () => {
    navigate(`/admin/add-user`);
  };

  const resetSessions = async () => {
    const confirmReset = window.confirm('Tem certeza que deseja reiniciar todas as sessões?');
    if (!confirmReset) return;
  
    try {
      await axios.post(
        '/api/admin/users/reset-sessions',
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      alert('Sessões reiniciadas com sucesso.');
    } catch (err) {
      console.error(err.response.data);
      alert('Erro ao reiniciar sessões.');
    }
  };
  
  return (
    <div className={styles['users-container']}>
      <h1 className={styles['users-header']}>Usuários</h1>
      
      {/* Dashboard de estatísticas */}
      <div className={styles['user-dashboard']}>
        <p>Total de Usuários: {stats.totalUsers}</p>
        <p>Usuários Pagantes: {stats.paidUsers}</p>
        <p>Usuários Não Pagantes: {stats.unpaidUsers}</p>
      </div>
      
      {/* Botão para reiniciar sessões */}
      <button onClick={resetSessions} className={styles['reset-sessions-button']}>
        Reiniciar Sessões
      </button>
      {/* Botão para adicionar um novo usuário */}
      <button onClick={addUser} className={styles['add-user-button']}>Adicionar Usuário</button>

      {/* Tabela de usuários */}
      <table className={styles['users-table']}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Pagante</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.isPaidUser ? 'Sim' : 'Não'}</td>
              <td>
                <button onClick={() => handleEdit(user._id)} className={styles['action-button']}>Editar</button>
                <button onClick={() => removeUser(user._id)} className={`${styles['action-button']} ${styles['remove-button']}`}>Remover</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;