import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../components/contexts/UserContext';
import styles from '../styles/components/Navbar.module.css';

const Navbar = () => {
  const { user, isPaidUser, logout, logoUrl, syncUserData } = useContext(UserContext) || {};
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);

  // Alterna o estado do menu (abre/fecha)
  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  // Fecha o menu manualmente
  const closeMenu = () => {
    setMenuActive(false);
  };

  // Polling para verificar mudanças no estado do usuário (caso o pagamento seja feito)
  useEffect(() => {
    if (user && !isPaidUser) {
      const interval = setInterval(() => {
        syncUserData(); // Atualiza os dados do usuário
      }, 1000); // Verifica a cada 2 segundos

      return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }
  }, [user, isPaidUser, syncUserData]);

  return (
    <nav className={styles['navbar']}>
      {/* Logo do sistema */}
      <div className={styles['navbar-logo']} onClick={() => { navigate(''); closeMenu(); }}>
        <img src={logoUrl} alt="Logo" />
      </div>

      {/* Overlay para o menu mobile */}
      {menuActive && <div className={styles['menu-overlay']} onClick={closeMenu}></div>}

      {/* Menu principal */}
      <div className={`${styles['navbar-menu']} ${menuActive ? styles['mobile-active'] : ''}`}>
        {!user ? (
          <>
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/login" onClick={closeMenu}>Login</Link></li>
            <li><Link to="/register" onClick={closeMenu}>Cadastre-se</Link></li>
            <li><Link to="/pagamento" onClick={closeMenu}>Adquira Já</Link></li>
          </>
        ) : !isPaidUser ? (
          <>
            <li><Link to="/minhaconta" onClick={closeMenu}>Minha Conta</Link></li>
            <li>
              <a href="https://wa.me/5551910056050" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>
                Suporte
              </a>
            </li>
            <li><button onClick={() => { logout(); closeMenu(); }}>Sair</button></li>
          </>
        ) : (
          <>
            <li><Link to="/dashboard" onClick={closeMenu}>Jogos de Hoje</Link></li>
            <li><Link to="/jogos-anteriores" onClick={closeMenu}>Jogos Anteriores</Link></li>
            <li><Link to="/minhaconta" onClick={closeMenu}>Minha Conta</Link></li>
            <li>
              <a href="https://wa.me/5551910056050" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>
                Suporte
              </a>
            </li>
            <li><button onClick={() => { logout(); closeMenu(); }}>Sair</button></li>
          </>
        )}
      </div>

      {/* Botão do menu hamburguer */}
      <div
        className={`${styles['hamburger']} ${menuActive ? styles['active'] : ''}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Navbar;
