import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUsers, FaGamepad, FaMoneyBillWave, FaCog, FaSignOutAlt, FaBars, FaHome } from 'react-icons/fa';
import styles from '../styles/components/AdminMenu.module.css';

const AdminMenu = ({ isCollapsed, onToggle }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); 
    navigate('/admin/login'); 
  };

  return (
    <div className={`${styles['admin-menu']} ${isCollapsed ? styles['collapsed'] : ''}`}>
      <button className={styles['hamburger']} onClick={onToggle}><FaBars /></button>
      
      {!isCollapsed && (
        <>
          <h3 className={styles['section-header']}>Visão Geral</h3>
          <ul className={styles['menu-list']}>
            <li className={styles['menu-item']}>
              <FaHome className={styles['menu-item-icon']} />
              <Link to="/admin/dashboard" className={styles['menu-item-text']}>Dashboard</Link>
            </li>
            <li className={styles['menu-item']}>
              <FaUsers className={styles['menu-item-icon']} />
              <Link to="/admin/users" className={styles['menu-item-text']}>Usuários</Link>
            </li>
            <li className={styles['menu-item']}>
              <FaMoneyBillWave className={styles['menu-item-icon']} />
              <Link to="/admin/deposits" className={styles['menu-item-text']}>Depósitos</Link>
            </li>
            <li className={styles['menu-item']}>
              <FaGamepad className={styles['menu-item-icon']} />
              <Link to="/admin/games" className={styles['menu-item-text']}>Jogos</Link>
            </li>
            <li className={styles['menu-item']}>
              <FaGamepad className={styles['menu-item-icon']} />
              <Link to="/admin/teamsandcoutries" className={styles['menu-item-text']}>Times e Países</Link>
            </li>
          </ul>

          <h3 className={styles['section-header']}>Configurações</h3>
          <ul className={styles['menu-list']}>
            <li className={styles['menu-item']}>
              <FaCog className={styles['menu-item-icon']} />
              <Link to="/admin/settings" className={styles['menu-item-text']}>Configurações Gerais</Link>
            </li>
            <li className={styles['menu-item']}>
              <FaMoneyBillWave className={styles['menu-item-icon']} />
              <Link to="/admin/payment-settings" className={styles['menu-item-text']}>Configurações de Pagamento</Link>
            </li>
            <li className={styles['menu-item']}>
              <FaUsers className={styles['menu-item-icon']} />
              <Link to="/admin/admins" className={styles['menu-item-text']}>Administradores</Link>
            </li>
          </ul>
          <button onClick={handleLogout} className={styles['logout-button']}>
            <FaSignOutAlt /> Sair
          </button>
        </>
      )}
    </div>
  );
};

export default AdminMenu;