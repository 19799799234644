import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/pages/AddUser.module.css';

const AddUser = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    isPaidUser: false
  });
  const navigate = useNavigate();

  const onChange = (e) => setUserData({ ...userData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/admin/users', userData, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      alert('Usuário adicionado com sucesso!');
      navigate('/admin/users');
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles['form-container']}>
      <h2 className={styles['form-title']}>Adicionar Usuário</h2>
      <input
        type="text"
        name="name"
        value={userData.name}
        onChange={onChange}
        placeholder="Nome"
        required
        className={styles['form-input']}
      />
      <input
        type="email"
        name="email"
        value={userData.email}
        onChange={onChange}
        placeholder="Email"
        required
        className={styles['form-input']}
      />
      <input
        type="text"
        name="phone"
        value={userData.phone}
        onChange={onChange}
        placeholder="Telefone"
        required
        className={styles['form-input']}
      />
      <input
        type="password"
        name="password"
        value={userData.password}
        onChange={onChange}
        placeholder="Senha"
        required
        className={styles['form-input']}
      />
      <label className={styles['checkbox-label']}>
        Pagante:
        <input
          type="checkbox"
          name="isPaidUser"
          checked={userData.isPaidUser}
          onChange={() => setUserData({ ...userData, isPaidUser: !userData.isPaidUser })}
        />
      </label>
      <button type="submit" className={styles['submit-button']}>Adicionar</button>
    </form>
  );
};

export default AddUser;
