import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../styles/pages/EditAdmin.module.css';

const EditAdmin = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const res = await axios.get(`/api/admin/admins/${id}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setFormData({
          name: res.data.name,
          email: res.data.email
        });
      } catch (err) {
        console.error(err.response.data);
      }
    };
    fetchAdmin();
  }, [id]);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/admin/admins/${id}`, formData, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      navigate('/admin/admins');
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles['form-container']}>
      <h2 className={styles['form-title']}>Editar Administrador</h2>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={onChange}
        placeholder="Nome"
        required
        className={styles['form-input']}
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={onChange}
        placeholder="Email"
        required
        className={styles['form-input']}
      />
      <button type="submit" className={styles['submit-button']}>Salvar Alterações</button>
    </form>
  );
};

export default EditAdmin;
