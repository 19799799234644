import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'; // Importa o componente reCAPTCHA
import styles from '../styles/pages/Register.module.css';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });
  const [recaptchaToken, setRecaptchaToken] = useState(''); // Estado para o token do reCAPTCHA
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const { name, email, phone, password, confirmPassword } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token); // Armazena o token do reCAPTCHA
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validação de senha
    if (password !== confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }

    // Validação de telefone
    const phoneRegex = /^\+?[1-9]\d{1,14}$/; // Aceita números no formato E.164 (+55 etc.)
    if (!phoneRegex.test(phone)) {
      setError('Número de telefone inválido. Use o formato internacional.');
      return;
    }

    
    if (!recaptchaToken) {
      setError('Por favor, confirme o reCAPTCHA');
      return;
    }

    try {
      // Realiza o registro do usuário
      const res = await axios.post('/api/users/register', { ...formData, recaptchaToken });
      //const res = await axios.post('/api/users/register', { ...formData });
      
      // Salva o token e o sessionToken no localStorage
      const { token, sessionToken } = res.data;
      localStorage.setItem('token', token);
      localStorage.setItem('sessionToken', sessionToken);

      // Autentica o usuário no contexto
      const userRes = await axios.get('/api/users/me', {
        headers: {
          Authorization: `Bearer ${token}`,
          'session-token': sessionToken,
        },
      });

      // Salva o usuário no contexto e redireciona para pagamento
      localStorage.setItem('user', JSON.stringify(userRes.data));
      navigate('/pagamento');
    } catch (err) {
      setError(err.response?.data?.msg || 'Erro ao criar a conta');
    }
  };

  return (
    <div className={styles['register-container']}>
      <form className={styles['register-form']} onSubmit={onSubmit}>
        <h2>Cadastro</h2>
        {error && <p className={styles['error-message']}>{error}</p>}

        <input
          type="text"
          name="name"
          value={name}
          onChange={onChange}
          placeholder="Nome"
          required
          className={styles['register-input']}
        />

        <input
          type="email"
          name="email"
          value={email}
          onChange={onChange}
          placeholder="Email"
          required
          className={styles['register-input']}
        />

        <input
          type="text"
          name="phone"
          value={phone}
          onChange={onChange}
          placeholder="Telefone"
          required
          className={styles['register-input']}
        />

        <input
          type="password"
          name="password"
          value={password}
          onChange={onChange}
          placeholder="Senha"
          required
          className={styles['register-input']}
        />

        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={onChange}
          placeholder="Confirme a Senha"
          required
          className={styles['register-input']}
        />

        <ReCAPTCHA
          sitekey="6Lctl3kqAAAAAGpPEBOsxCkhwvUQP_zbvw-xyYWV"
          onChange={onRecaptchaChange}
          className={styles['recaptcha']}
        />

        <button type="submit" className={styles['register-button']}>Cadastrar</button>
      </form>
    </div>
  );
};

export default Register;
