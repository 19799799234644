import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Modal from 'react-modal';
import Footer from './Footer';
import styles from '../styles/pages/GamesOldDashboard.module.css';

const GamesOldDashboard = () => {
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [betIndex, setBetIndex] = useState(0);
  const [greenCount, setGreenCount] = useState(0);
  const [redCount, setRedCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get(`/api/admin/list-games?page=${currentPage}`);
        setGames(response.data.previousGames);
        setTotalPages(response.data.totalPages);

        // Contar jogos "green" e "red"
        const greenGames = response.data.previousGames.filter((game) => game.status === 'Green');
        const redGames = response.data.previousGames.filter((game) => game.status === 'Red');
        setGreenCount(greenGames.length);
        setRedCount(redGames.length);
      } catch (error) {
        console.error('Erro ao carregar jogos anteriores:', error);
      }
    };
    fetchGames();
  }, [currentPage]);

  const openModal = (game) => {
    setSelectedGame(game);
    setBetIndex(0);
  };

  const closeModal = () => {
    setSelectedGame(null);
    setBetIndex(0);
  };

  const nextBet = () => {
    if (betIndex < selectedGame.bets.length - 1) {
      setBetIndex(betIndex + 1);
    }
  };

  const formatDate = (dateString) => {
    // Dividir a string ISO em data e hora
    const [date, time] = dateString.split('T');
    
    // Reorganizar a data para o formato dd/MM/yyyy
    const [year, month, day] = date.split('-');
    const formattedDate = `${day}/${month}/${year}`;
    
    // Retornar a data e hora no formato desejado
    return `${time.slice(0, 5)} ${formattedDate}`; // Exibe somente HH:mm
  };

  const prevBet = () => {
    if (betIndex > 0) {
      setBetIndex(betIndex - 1);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className={styles['games-old-dashboard']}>
      <Navbar />
      <div className={styles['games-table-container']}>
        <h2>Jogos Anteriores</h2>
        <div className={styles['stats-container']}>
          <div className={styles['stat-item']}>
            <h3>Green</h3>
            <p>{greenCount}</p>
          </div>
          <div className={styles['stat-item']}>
            <h3>Red</h3>
            <p>{redCount}</p>
          </div>
        </div>
        <table className={styles['games-table']}>
          <thead>
            <tr>
              <th>CONFRONTO</th>
              <th>ODD</th>
              <th>DATA E HORA</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {(games || []).map((game) => (
              <tr key={game._id} onClick={() => openModal(game)}>
                <td data-label="Confronto">
                  <img src={game.teamA_badge} alt={game.teamA_name} width="40" />
                  <span className="vs-separator"> x </span>
                  <img src={game.teamB_badge} alt={game.teamB_name} width="40" />
                </td>
                <td data-label="ODD">{game.bets[0]?.odds || 'N/A'}</td>
                <td data-label="Data e Hora">{formatDate(game.matchDate)}</td>
                <td data-label="Status">
                  <span className={`${styles['status']} ${styles[game.status.toLowerCase()]}`}>
                    {game.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Botões de Paginação */}
        <div className={styles['pagination']}>
          <button
            onClick={() => handlePageChange('prev')}
            disabled={currentPage === 1}
            className={styles['pagination-button']}
          >
            Anterior
          </button>
          <span className={styles['pagination-info']}>
            Página {currentPage} de {totalPages}
          </span>
          <button
            onClick={() => handlePageChange('next')}
            disabled={currentPage === totalPages}
            className={styles['pagination-button']}
          >
            Próximo
          </button>
        </div>
      </div>

      <Modal
        isOpen={!!selectedGame}
        onRequestClose={closeModal}
        className={styles['game-modal']}
        overlayClassName={styles['modal-overlay']}
      >
        {selectedGame && (
          <div className={styles['modal-content']}>
            <div className={styles['modal-header']}>
              <div className={styles['team-container']}>
                <img src={selectedGame.teamA_badge} alt={selectedGame.teamA_name} width="60" />
                <span className={styles['team-name']}>{selectedGame.teamA_name}</span>
              </div>

              <div className={styles['vs-container']}>
              <span className={styles['game-date']}>
              {formatDate(selectedGame.matchDate)}
              </span>
                <span className={styles['vs-separator-large']}> - </span>
              </div>

              <div className={styles['team-container']}>
                <img src={selectedGame.teamB_badge} alt={selectedGame.teamB_name} width="60" />
                <span className={styles['team-name']}>{selectedGame.teamB_name}</span>
              </div>
            </div>

            <div className={styles['modal-title-container']}>
              <h3>{selectedGame.bets[betIndex]?.title}</h3>
              <div className={styles['odd-container']}>
                <span className={styles['odd-label']}>ODD:</span>
                <span className={styles['odd']}>{selectedGame.bets[betIndex]?.odds}</span>
              </div>
            </div>

            <p className={styles['subtitle']}>{selectedGame.bets[betIndex]?.subtitle}</p>

            <div className={styles['bet-navigation']}>
              {selectedGame?.bets.length > 1 && (
                <>
                  <button className={styles['bet-navigation prev-button']} onClick={prevBet} disabled={betIndex === 0}>
                    ◄
                  </button>
                  <button className={styles['bet-navigation next-button']} onClick={nextBet} disabled={betIndex === selectedGame.bets.length - 1}>
                    ►
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </Modal>

      <Footer />
    </div>
  );
};

export default GamesOldDashboard;
