import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import styles from '../styles/pages/AdminGames.module.css';
import makeAnimated from 'react-select/animated';

const AdminGames = () => {
  const [games, setGames] = useState([]);
  const [previousGames, setPreviousGames] = useState([]);
  const [showTodayGames, setShowTodayGames] = useState(true);
  const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [newGame, setNewGame] = useState({ teamA: '', teamB: '', matchDate: '', matchLink: '', bets: [] });
  const [editGame, setEditGame] = useState(null);
  const [teams, setTeams] = useState([]);
  const [deleteGameId, setDeleteGameId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const animatedComponents = makeAnimated();

  // Função fetchGames encapsulada com useCallback
  const fetchGames = useCallback(async () => {
    try {
      const response = await axios.get(`/api/admin/list-games?page=${currentPage}`);
      setGames(response.data.todayGames);
      setPreviousGames(response.data.previousGames);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Erro ao carregar jogos:', error);
    }
  }, [currentPage]); // Dependência do currentPage

  useEffect(() => {
    fetchGames(); // Chama fetchGames ao carregar o componente ou alterar a página
  }, [fetchGames]);

  const fetchTeams = async () => {
    try {
      const response = await axios.get('/api/admin/list-teams');
      const formattedTeams = response.data.teams.map((team) => ({
        value: team._id,
        label: team.name,
        badge: team.badge,
      }));
      setTeams(formattedTeams);
    } catch (error) {
      console.error('Erro ao carregar times:', error);
    }
  };

  useEffect(() => {
    fetchTeams(); // Chama fetchTeams uma vez ao carregar o componente
  }, []);

  const toggleShowGames = () => {
    setShowTodayGames(!showTodayGames);
  };

  const openDeleteConfirm = (gameId) => {
    setDeleteGameId(gameId);
  };

  const handleDeleteGame = async () => {
    try {
      await axios.delete(`/api/admin/delete-game/${deleteGameId}`);
      setDeleteGameId(null);
      fetchGames();
    } catch (error) {
      console.error('Erro ao excluir jogo:', error);
    }
  };

  const handleStatusChange = async (gameId, status) => {
    try {
      await axios.put(`/api/admin/edit-game/${gameId}`, { status });
      fetchGames();
    } catch (error) {
      console.error('Erro ao mudar status do jogo:', error);
    }
  };

  const handleCreateGame = async () => {
    try {
      const gameToCreate = { ...newGame };
      await axios.post('/api/admin/create-game', gameToCreate);
      setModalCreateIsOpen(false);
      fetchGames();
    } catch (error) {
      console.error('Erro ao criar jogo:', error);
    }
  };

  const formatDate = (dateString) => {
    const [date, time] = dateString.split('T');
    const [year, month, day] = date.split('-');
    const formattedDate = `${day}/${month}/${year}`;
    return `${formattedDate} ${time.slice(0, 5)}`;
  };

  const handleEditGame = async () => {
    try {
      const gameToEdit = { ...editGame };
      await axios.put(`/api/admin/edit-game/${editGame._id}`, gameToEdit);
      setModalEditIsOpen(false);
      fetchGames();
    } catch (error) {
      console.error('Erro ao editar jogo:', error);
    }
  };

  const handleInputChange = (e, game, setGame) => {
    const { name, value } = e.target;
    setGame({ ...game, [name]: value });
  };

  const handleTeamChange = (selectedOption, teamKey, game, setGame) => {
    setGame({ ...game, [teamKey]: selectedOption ? selectedOption.value : '' });
  };

  const handleAddBet = (game, setGame) => {
    setGame({
      ...game,
      bets: [...game.bets, { title: '', subtitle: '', odds: '' }],
    });
  };

  const handleBetChange = (index, field, value, game, setGame) => {
    const updatedBets = [...game.bets];
    updatedBets[index][field] = value;
    setGame({ ...game, bets: updatedBets });
  };

  const openEditModal = (game) => {
    setEditGame(game);
    setModalEditIsOpen(true);
  };

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className={styles['admin-games-container']}>
      <h1 className={styles['header']}>Gerenciamento de Jogos</h1>
      <div className={styles['buttons-container']}>
        <Button onClick={() => setModalCreateIsOpen(true)} className={styles['create-button']}>
          Criar Novo Jogo
        </Button>
        <Button
          onClick={async () => {
            try {
              await axios.post('/api/admin/notify-new-games', { games });
              alert('Notificações enviadas com sucesso!');
            } catch (error) {
              console.error('Erro ao enviar notificações:', error);
              alert('Erro ao enviar notificações.');
            }
          }}
          className={styles['create-button']}
        >
          Notificar Usuários
        </Button>
        <Button onClick={toggleShowGames} className={styles['toggle-button']}>
          {showTodayGames ? 'Exibir Jogos Anteriores' : 'Exibir Jogos de Hoje'}
        </Button>
      </div>
      <table className={styles['games-table']}>
        <thead>
          <tr>
            <th>Time A</th>
            <th>Time B</th>
            <th>Data do Jogo</th>
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {(showTodayGames ? games : previousGames).map((game) => (
            <tr key={game._id}>
              <td>
                {game.teamA_badge && <img src={game.teamA_badge} alt={game.teamA.name} width="20" style={{ marginRight: '8px' }} />}
                {game.teamA.name}
              </td>
              <td>
                {game.teamB_badge && <img src={game.teamB_badge} alt={game.teamB.name} width="20" style={{ marginRight: '8px' }} />}
                {game.teamB.name}
              </td>
              <td>{formatDate(game.matchDate)}</td>
              <td>
                <select
                  onChange={(e) => handleStatusChange(game._id, e.target.value)}
                  value={game.status}
                  className={styles['status-select']}
                >
                  <option value="Pendente">Pendente</option>
                  <option value="Green">Green</option>
                  <option value="Red">Red</option>
                </select>
              </td>
              <td>
                <Button onClick={() => openEditModal(game)} className={styles['edit-button']}>
                  Editar
                </Button>
                <Button
                  variant="danger"
                  onClick={() => openDeleteConfirm(game._id)}
                  className={styles['delete-button']}
                >
                  Excluir
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Paginação */}
      <div className={styles['pagination']}>
        <button
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 1}
          className={styles['pagination-button']}
        >
          Anterior
        </button>
        <span className={styles['pagination-info']}>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={() => handlePageChange('next')}
          disabled={currentPage === totalPages}
          className={styles['pagination-button']}
        >
          Próximo
        </button>
      </div>

{/* Modal de criação de jogos */}
<Modal
    isOpen={modalCreateIsOpen}
    onRequestClose={() => setModalCreateIsOpen(false)}
    className={styles['modal']}
    overlayClassName={styles['modal-overlay']}
>
    <h2 className={styles['modal-title']}>Criar Novo Jogo</h2>
    <form>
        <div className={styles['form-group']}>
        <label className={styles['modal-label']}>Time A</label>
        <Select
  options={teams}
  components={animatedComponents}
  onChange={(selectedOption) => handleTeamChange(selectedOption, 'teamA', newGame, setNewGame)}
  placeholder="Selecione o Time A"
  isSearchable={true} // Permite pesquisa no Select
  getOptionLabel={(e) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={e.badge} // Use o brasão para exibição
        alt={e.label}
        width="20"
        style={{ marginRight: '10px' }}
      />
      {e.label} {/* Nome do time */}
    </div>
  )}
  styles={{
    control: (base) => ({
      ...base,
      backgroundColor: '#333',
      borderColor: '#555',
      color: '#e0e0e0',
      borderRadius: '5px',
      padding: '0.4rem',
      boxShadow: 'none',
      '&:hover': { borderColor: '#00e676' },
    }),
    placeholder: (base) => ({
      ...base,
      color: '#e0e0e0',
    }),
    singleValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      color: '#e0e0e0',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: '#333',
      borderRadius: '5px',
    }),
    option: (base, state) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: state.isFocused ? '#00e676' : '#333',
      color: state.isFocused ? '#1a1a1a' : '#e0e0e0',
      '&:active': { backgroundColor: '#00a844' },
    }),
  }}
/>
        </div>
        <div className={styles['form-group']}>
        <label className={styles['modal-label']}>Time B</label>
        <Select
  options={teams}
  components={animatedComponents}
  onChange={(selectedOption) => handleTeamChange(selectedOption, 'teamB', newGame, setNewGame)}
  placeholder="Selecione o Time B"
  isSearchable={true} // Permite pesquisa no Select
  getOptionLabel={(e) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={e.badge} // Use o brasão para exibição
        alt={e.label}
        width="20"
        style={{ marginRight: '10px' }}
      />
      {e.label} {/* Nome do time */}
    </div>
  )}
  styles={{
    control: (base) => ({
      ...base,
      backgroundColor: '#333',
      borderColor: '#555',
      color: '#e0e0e0',
      borderRadius: '5px',
      padding: '0.4rem',
      boxShadow: 'none',
      '&:hover': { borderColor: '#00e676' },
    }),
    placeholder: (base) => ({
      ...base,
      color: '#e0e0e0',
    }),
    singleValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      color: '#e0e0e0',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: '#333',
      borderRadius: '5px',
    }),
    option: (base, state) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: state.isFocused ? '#00e676' : '#333',
      color: state.isFocused ? '#1a1a1a' : '#e0e0e0',
      '&:active': { backgroundColor: '#00a844' },
    }),
  }}
/>
        </div>
        <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Data do Jogo</label>
            <input
                type="datetime-local"
                name="matchDate"
                onChange={(e) => handleInputChange(e, newGame, setNewGame)}
                className={styles['modal-input']}
            />
        </div>
        <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Link do Jogo</label>
            <input
                type="text"
                name="matchLink"
                onChange={(e) => handleInputChange(e, newGame, setNewGame)}
                className={styles['modal-input']}
            />
        </div>
        
        <div className={styles['form-group']}>
  <label className={styles['modal-label']}>Probabilidade de Acerto (%)</label>
  <input
    type="number"
    name="probabilidade"
    value={newGame.probabilidade || ''}
    onChange={(e) => handleInputChange(e, newGame, setNewGame)}
    className={styles['modal-input']}
    min="0"
    max="100"
  />
</div>

        <h5 className={styles['modal-subtitle']}>Apostas</h5>
        {newGame.bets.map((bet, index) => (
            <div key={index} className={styles['bet-group']}>
                <div className={styles['form-group']}>
                    <label className={styles['modal-label']}>Título</label>
                    <input
                        type="text"
                        value={bet.title}
                        onChange={(e) => handleBetChange(index, 'title', e.target.value, newGame, setNewGame)}
                        className={styles['modal-input']}
                    />
                </div>
                <div className={styles['form-group']}>
                    <label className={styles['modal-label']}>Subtítulo</label>
                    <input
                        type="text"
                        value={bet.subtitle}
                        onChange={(e) => handleBetChange(index, 'subtitle', e.target.value, newGame, setNewGame)}
                        className={styles['modal-input']}
                    />
                </div>
                <div className={styles['form-group']}>
                    <label className={styles['modal-label']}>ODDS</label>
                    <input
                        type="text"
                        value={bet.odds}
                        onChange={(e) => handleBetChange(index, 'odds', e.target.value, newGame, setNewGame)}
                        className={styles['modal-input']}
                    />
                </div>
            </div>
        ))}
        <button type="button" onClick={() => handleAddBet(newGame, setNewGame)} className={styles['add-bet-button']}>
            Adicionar Aposta
        </button>
    </form>
    <div className={styles['modal-actions']}>
        <button onClick={() => setModalCreateIsOpen(false)} className={styles['modal-cancel-button']}>Cancelar</button>
        <button onClick={handleCreateGame} className={styles['modal-create-button']}>Criar Jogo</button>
    </div>
</Modal>

{/* Modal de exclusão */}
<Modal
    isOpen={!!deleteGameId}
    onRequestClose={() => setDeleteGameId(null)}
    className={styles['modal']}
    overlayClassName={styles['modal-overlay']}
>
    <h2 className={styles['modal-title']}>Confirmar Exclusão</h2>
    <p className={styles['modal-text']}>Tem certeza de que deseja excluir este jogo?</p>
    <div className={styles['modal-actions']}>
        <button onClick={handleDeleteGame} className={styles['modal-cancel-button']}>Confirmar</button>
        <button onClick={() => setDeleteGameId(null)} className={styles['modal-create-button']}>Cancelar</button>
    </div>
</Modal>

{/* Modal de edição de jogos */}
<Modal
    isOpen={modalEditIsOpen}
    onRequestClose={() => setModalEditIsOpen(false)}
    className={styles['modal']}
    overlayClassName={styles['modal-overlay']}
>
    <h2 className={styles['modal-title']}>Editar Jogo</h2>
    {editGame && (
        <form>
            <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Time A</label>
            <Select
                options={teams}
                onChange={(selectedOption) => handleTeamChange(selectedOption, 'teamA', newGame, setNewGame)}
                placeholder="Selecione o Time A"
                styles={{
                    control: (base) => ({
                        ...base,
                        backgroundColor: '#333',
                        borderColor: '#555',
                        color: '#e0e0e0',
                        borderRadius: '5px',
                        padding: '0.4rem',
                        boxShadow: 'none',
                        '&:hover': { borderColor: '#00e676' }
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#e0e0e0',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#e0e0e0',
                    }),
                    menu: (base) => ({
                        ...base,
                        backgroundColor: '#333',
                        borderRadius: '5px',
                    }),
                    option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused ? '#00e676' : '#333',
                        color: state.isFocused ? '#1a1a1a' : '#e0e0e0',
                        '&:active': { backgroundColor: '#00a844' },
                    })
                }}
            />
        </div>
            <div className={styles['form-group']}>
            <label className={styles['modal-label']}>Time B</label>
            <Select
                options={teams}
                onChange={(selectedOption) => handleTeamChange(selectedOption, 'teamB', newGame, setNewGame)}
                placeholder="Selecione o Time B"
                styles={{
                    control: (base) => ({
                        ...base,
                        backgroundColor: '#333',
                        borderColor: '#555',
                        color: '#e0e0e0',
                        borderRadius: '5px',
                        padding: '0.4rem',
                        boxShadow: 'none',
                        '&:hover': { borderColor: '#00e676' }
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#e0e0e0',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: '#e0e0e0',
                    }),
                    menu: (base) => ({
                        ...base,
                        backgroundColor: '#333',
                        borderRadius: '5px',
                    }),
                    option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused ? '#00e676' : '#333',
                        color: state.isFocused ? '#1a1a1a' : '#e0e0e0',
                        '&:active': { backgroundColor: '#00a844' },
                    })
                }}
            />
        </div>
            <div className={styles['form-group']}>
                <label className={styles['modal-label']}>Data do Jogo</label>
                <input
  type="datetime-local"
  name="matchDate"
  value={
    editGame?.matchDate
      ? editGame.matchDate.slice(0, 16)
      : ''
  }
  onChange={(e) => handleInputChange(e, editGame, setEditGame)}
  className={styles['modal-input']}
/>


            </div>
            <div className={styles['form-group']}>
                <label className={styles['modal-label']}>Link do Jogo</label>
                <input
                    name="matchLink"
                    value={editGame.matchLink || ''}
                    onChange={(e) => handleInputChange(e, editGame, setEditGame)}
                    className={styles['modal-input']}
                />
            </div>

            <div className={styles['form-group']}>
  <label className={styles['modal-label']}>Probabilidade de Acerto (%)</label>
  <input
    type="number"
    name="probabilidade"
    value={editGame.probabilidade || ''}
    onChange={(e) => handleInputChange(e, editGame, setEditGame)}
    className={styles['modal-input']}
    min="0"
    max="100"
  />
</div>

            <h5 className={styles['modal-subtitle']}>Apostas</h5>
            {editGame.bets.map((bet, index) => (
                <div key={index} className="mb-3">
                    <div className={styles['form-group']}>
                        <label className={styles['modal-label']}>Título</label>
                        <input
                            type="text"
                            value={bet.title}
                            onChange={(e) => handleBetChange(index, 'title', e.target.value, editGame, setEditGame)}
                            className={styles['modal-input']}
                        />
                    </div>
                    <div className={styles['form-group']}>
                        <label className={styles['modal-label']}>Subtítulo</label>
                        <input
                            type="text"
                            value={bet.subtitle}
                            onChange={(e) => handleBetChange(index, 'subtitle', e.target.value, editGame, setEditGame)}
                            className={styles['modal-input']}
                        />
                    </div>
                    <div className={styles['form-group']}>
                        <label className={styles['modal-label']}>ODDS</label>
                        <input
                            type="text"
                            value={bet.odds}
                            onChange={(e) => handleBetChange(index, 'odds', e.target.value, editGame, setEditGame)}
                            className={styles['modal-input']}
                        />
                    </div>
                </div>
            ))}
            <button type="button" onClick={() => handleAddBet(editGame, setEditGame)} className={styles['add-bet-button']}>
                Adicionar Aposta
            </button>
        </form>
    )}
    <div className={styles['modal-actions']}>
        <button onClick={() => setModalEditIsOpen(false)} className={styles['modal-cancel-button']}>Cancelar</button>
        <button onClick={handleEditGame} className={styles['modal-create-button']}>Salvar Alterações</button>
    </div>
</Modal>

    </div>
  );
};

export default AdminGames;