import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [isPaidUser, setIsPaidUser] = useState(() => {
    const savedIsPaidUser = localStorage.getItem('isPaidUser');
    return savedIsPaidUser ? JSON.parse(savedIsPaidUser) : false;
  });

  const [logoUrl, setLogoUrl] = useState('');
  const [systemName, setSystemName] = useState('');

  const isAdmin = user?.isAdmin;

  const handleLogout = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const sessionToken = localStorage.getItem('sessionToken');
      if (token && sessionToken) {
        await axios.post(
          '/api/users/logout',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'session-token': sessionToken,
            },
          }
        );
      }
    } catch (err) {
      console.error('Erro ao fazer logout:', err);
    }

    setUser(null);
    setIsPaidUser(false);
    localStorage.removeItem('user');
    localStorage.removeItem('isPaidUser');
    localStorage.removeItem('token');
    localStorage.removeItem('sessionToken');

    navigate(isAdmin ? '/admin/login' : '/login');
  }, [navigate, isAdmin]);

  const syncUserData = useCallback(async () => {
    const token = localStorage.getItem('token');
    const sessionToken = localStorage.getItem('sessionToken');
    if (!token || !sessionToken) {
      handleLogout();
      return;
    }
  
    try {
      const res = await axios.get('/api/users/me', {
        headers: {
          Authorization: `Bearer ${token}`,
          'session-token': sessionToken,
        },
      });
  
      const updatedUser = res.data;
  
      setUser(updatedUser);
      setIsPaidUser(updatedUser.isPaidUser);
  
      localStorage.setItem('user', JSON.stringify(updatedUser));
      localStorage.setItem('isPaidUser', JSON.stringify(updatedUser.isPaidUser));
    } catch (err) {
      console.error('Erro ao sincronizar dados do usuário:', err);
      handleLogout();
    }
  }, [handleLogout]);

  useEffect(() => {
    const fetchGeneralSettings = async () => {
      try {
        const res = await axios.get('/api/admin/public-general-settings');
        const baseUrl = window.location.origin;
        setLogoUrl(baseUrl + res.data.logoUrl);
        setSystemName(res.data.systemName);
      } catch (err) {
        console.error('Erro ao carregar as configurações gerais:', err);
      }
    };

    fetchGeneralSettings();
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem('isPaidUser', JSON.stringify(isPaidUser));
  }, [isPaidUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        isPaidUser,
        setUser,
        setIsPaidUser,
        syncUserData,
        logout: handleLogout,
        logoUrl,
        systemName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
