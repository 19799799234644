import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/pages/AddAdmin.module.css';

const AddAdmin = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
  });
  const navigate = useNavigate();

  const { name, email, phone, password } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/admin/add-admin', formData, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      alert('Administrador adicionado com sucesso!');
      navigate('/admin/admins');
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles['form-container']}>
      <h2 className={styles['form-title']}>Adicionar Administrador</h2>
      <input
        type="text"
        name="name"
        value={name}
        onChange={onChange}
        placeholder="Nome"
        required
        className={styles['form-input']}
      />
      <input
        type="email"
        name="email"
        value={email}
        onChange={onChange}
        placeholder="Email"
        required
        className={styles['form-input']}
      />
      <input
        type="text"
        name="phone"
        value={phone}
        onChange={onChange}
        placeholder="Telefone"
        required
        className={styles['form-input']}
      />
      <input
        type="password"
        name="password"
        value={password}
        onChange={onChange}
        placeholder="Senha"
        required
        className={styles['form-input']}
      />
      <button type="submit" className={styles['submit-button']}>Adicionar</button>
    </form>
  );
};

export default AddAdmin;
