import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/pages/AdminLogin.module.css';
import { UserContext } from '../components/contexts/UserContext';

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { logoUrl } = useContext(UserContext) || {};

  const { email, password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();

    try {
      const res = await axios.post('/api/users/admin/login', formData);

      // Salva o token no localStorage
      if (res.data.token) {
        localStorage.setItem('token', res.data.token);
        // Redireciona para o painel de administração
        navigate('/admin/dashboard');
      } else {
        setError('Erro ao fazer login, tente novamente.');
      }
    } catch (err) {
      console.error(err.response?.data || 'Erro no servidor');
      setError(err.response?.data?.msg || 'Erro no servidor');
    }
  };

  return (
    <div className={styles['admin-login-container']}>
      <div className={styles['logo-container']}>
        <img src={logoUrl} alt="Logo" className={styles['logo']} />
      </div>
      <h2 className={styles['title']}>Painel de Administrador</h2>
      
      <form onSubmit={onSubmit} className={styles['admin-login-form']}>
        {error && <p className={styles['error-message']}>{error}</p>}
        <input 
          type="email" 
          name="email" 
          value={email} 
          onChange={onChange} 
          placeholder="Email" 
          required
          className={styles['admin-input']}
        />
        <input 
          type="password" 
          name="password" 
          value={password} 
          onChange={onChange} 
          placeholder="Senha" 
          required
          className={styles['admin-input']}
        />
        <button type="submit" className={styles['admin-login-button']}>Entrar</button>
      </form>
    </div>
  );
};

export default AdminLogin;