import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../styles/pages/EditUser.module.css';

const EditUser = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    isPaidUser: false,
    password: '',
    licenseExpiresAt: ''
  });

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(`/api/admin/users/${id}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setFormData({
          name: res.data.name,
          email: res.data.email,
          phone: res.data.phone,
          isPaidUser: res.data.isPaidUser,
          licenseExpiresAt: res.data.licenseExpiresAt ? new Date(res.data.licenseExpiresAt).toISOString().split('T')[0] : '',
          password: '',
        });
      } catch (err) {
        console.error(err.response.data);
      }
    };
    fetchUser();
  }, [id]);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/admin/users/${id}`, formData, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      navigate('/admin/users');
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    <form onSubmit={onSubmit} className={styles['form-container']}>
      <h2 className={styles['form-title']}>Editar Usuário</h2>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={onChange}
        placeholder="Nome"
        className={styles['form-input']}
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={onChange}
        placeholder="Email"
        className={styles['form-input']}
      />
      <input
        type="text"
        name="phone"
        value={formData.phone}
        onChange={onChange}
        placeholder="Telefone"
        className={styles['form-input']}
      />
      <label className={styles['checkbox-label']}>
        Pagante:
        <input
          type="checkbox"
          name="isPaidUser"
          checked={formData.isPaidUser}
          onChange={(e) => setFormData({ ...formData, isPaidUser: e.target.checked })}
        />
      </label>
      <input
        type="password"
        name="password"
        value={formData.password}
        onChange={onChange}
        placeholder="Nova Senha (opcional)"
        className={styles['form-input']}
      />
      <label className={styles['form-label']}>
        Expiração da Licença:
        <input
          type="date"
          name="licenseExpiresAt"
          value={formData.licenseExpiresAt}
          onChange={onChange}
          className={styles['date-input']}
        />
      </label>
      <button type="submit" className={styles['submit-button']}>Salvar Alterações</button>
    </form>
  );
};

export default EditUser;
