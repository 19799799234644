import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Modal from 'react-modal';
import styles from '../styles/pages/UserAccount.module.css';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

const UserAccount = () => {
  const [user, setUser] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      const sessionToken = localStorage.getItem('sessionToken');

      if (!token || !sessionToken) {
        setError('Sessão expirada. Faça login novamente.');
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get('/api/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
            'session-token': sessionToken,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Erro ao carregar informações do usuário:', error);
        if (error.response?.status === 401 || error.response?.status === 403) {
          setError('Sessão expirada. Faça login novamente.');
          navigate('/login');
        } else {
          setError('Erro ao carregar informações do usuário. Tente novamente mais tarde.');
        }
      }
    };
    fetchUser();
  }, [navigate]);

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    const sessionToken = localStorage.getItem('sessionToken');

    if (!token || !sessionToken) {
      setError('Sessão expirada. Faça login novamente.');
      navigate('/login');
      return;
    }

    try {
      await axios.put(
        '/api/users/me',
        { name: user.name, email: user.email, phone: user.phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'session-token': sessionToken,
          },
        }
      );
      setIsEditable(false);
      alert('Informações salvas com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar informações do usuário:', error);
      alert('Ocorreu um erro ao salvar as informações. Por favor, tente novamente.');
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPassword('');
    setConfirmPassword('');
  };

  const handlePasswordReset = async () => {
    const token = localStorage.getItem('token');
    const sessionToken = localStorage.getItem('sessionToken');

    if (!token || !sessionToken) {
      setError('Sessão expirada. Faça login novamente.');
      navigate('/login');
      return;
    }

    if (newPassword !== confirmPassword) {
      alert('As senhas não coincidem. Por favor, tente novamente.');
      return;
    }

    try {
      await axios.put(
        '/api/users/update-password',
        { password: newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'session-token': sessionToken,
          },
        }
      );
      alert('Senha alterada com sucesso!');
      closeModal();
    } catch (error) {
      console.error('Erro ao redefinir senha:', error);
      alert('Ocorreu um erro ao tentar redefinir a senha. Por favor, tente novamente.');
    }
  };

  return (
    <div className={styles['user-account']}>
      <Navbar />
      <div className={styles['account-container']}>
        <h2>Minha Conta</h2>
        {error && <p className={styles['error-message']}>{error}</p>}
        <div className={styles['account-info']}>
          <div className={styles['form-group']}>
            <label>Nome</label>
            <input
              type="text"
              value={user.name || ''}
              disabled={!isEditable}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
              className={styles['input-field']}
            />
          </div>
          <div className={styles['form-group']}>
            <label>Email</label>
            <input
              type="email"
              value={user.email || ''}
              disabled={!isEditable}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              className={styles['input-field']}
            />
          </div>
          <div className={styles['form-group']}>
            <label>Telefone</label>
            <input
              type="text"
              value={user.phone || ''}
              disabled={!isEditable}
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
              className={styles['input-field']}
            />
          </div>
          <div className={styles['form-group']}>
            <label>Dias restantes de licença</label>
            <input
              type="text"
              value={user.remainingDays ? `${user.remainingDays} dias` : 'N/A'}
              disabled
              className={styles['input-field']}
            />
          </div>
        </div>
        <div className={styles['button-group']}>
          {isEditable ? (
            <button onClick={handleSave} className={styles['save-button']}>Salvar Informações</button>
          ) : (
            <button onClick={handleEdit} className={styles['edit-button']}>Editar Informações</button>
          )}
          <button onClick={openModal} className={styles['reset-password-button']}>Redefinir Senha</button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles['modal']}
        overlayClassName={styles['modal-overlay']}
      >
        <h2>Redefinir Senha</h2>
        <form>
          <div className={styles['form-group']}>
            <label>Nova Senha</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={styles['modal-input']}
            />
          </div>
          <div className={styles['form-group']}>
            <label>Confirmar Nova Senha</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={styles['modal-input']}
            />
          </div>
          <div className={styles['modal-actions']}>
            <button type="button" onClick={closeModal} className={styles['modal-cancel-button']}>Cancelar</button>
            <button type="button" onClick={handlePasswordReset} className={styles['modal-reset-button']}>Redefinir</button>
          </div>
        </form>
      </Modal>
      <Footer />
    </div>
  );
};

export default UserAccount;
