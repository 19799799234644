import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/pages/AdminPaymentSettings.module.css';

const AdminPaymentSettings = () => {
  const [settings, setSettings] = useState({
    paymentAmount: '',
    sqalapay: {
      isActive: false,
      appId: '',
      appSecret: '',
      refreshToken: '',
      endpoint: '',
      webhookSecret: ''
    }
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await axios.get('/api/admin/payment-settings', {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setSettings(res.data);
      } catch (err) {
        console.error('Erro ao obter configurações financeiras:', err);
        setError('Erro ao obter configurações financeiras.');
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('sqalapay')) {
      const [gateway, field] = name.split('.');
      setSettings({
        ...settings,
        [gateway]: {
          ...settings[gateway],
          [field]: field === 'isActive' ? e.target.checked : value,
        },
      });
    } else {
      setSettings({ ...settings, [name]: value });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/admin/payment-settings', settings, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      alert('Configurações salvas com sucesso!');
    } catch (err) {
      console.error('Erro ao salvar configurações financeiras', err);
      setError('Erro ao salvar configurações financeiras.');
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div className={styles['error-message']}>{error}</div>;
  }

  return (
    <form onSubmit={onSubmit} className={styles['form-container']}>
      <h2 className={styles['form-title']}>Configurações Financeiras</h2>

      <div className={styles['settings-group']}>
        <label className={styles['form-label']}>Valor do Pagamento (amount):</label>
        <input
          type="number"
          name="paymentAmount"
          value={settings?.paymentAmount || ''}
          onChange={onChange}
          required
          className={styles['form-input']}
        />
      </div>

      <h3 className={styles['section-title']}>SqalaPay</h3>
      <label className={styles['checkbox-label']}>
        <input
          type="checkbox"
          name="sqalapay.isActive"
          checked={settings?.sqalapay?.isActive || false}
          onChange={onChange}
          className={styles['form-checkbox']}
        />
        Ativo
      </label>

      <input
        type="text"
        name="sqalapay.appId"
        value={settings?.sqalapay?.appId || ''}
        onChange={onChange}
        placeholder="App ID SqalaPay"
        className={styles['form-input']}
      />

      <input
        type="text"
        name="sqalapay.appSecret"
        value={settings?.sqalapay?.appSecret || ''}
        onChange={onChange}
        placeholder="App Secret SqalaPay"
        className={styles['form-input']}
      />

      <input
        type="text"
        name="sqalapay.refreshToken"
        value={settings?.sqalapay?.refreshToken || ''}
        onChange={onChange}
        placeholder="Refresh Token SqalaPay"
        className={styles['form-input']}
      />

      <input
        type="text"
        name="sqalapay.endpoint"
        value={settings?.sqalapay?.endpoint || ''}
        onChange={onChange}
        placeholder="Endpoint SqalaPay"
        className={styles['form-input']}
      />

      <input
        type="text"
        name="sqalapay.webhookSecret"
        value={settings?.sqalapay?.webhookSecret || ''}
        onChange={onChange}
        placeholder="Webhook Secret SqalaPay"
        className={styles['form-input']}
      />

      <button type="submit" className={styles['submit-button']}>Salvar Configurações</button>
    </form>
  );
};

export default AdminPaymentSettings;
