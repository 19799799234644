import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Modal from 'react-modal';
import Footer from './Footer';
import styles from '../styles/pages/UserDashboard.module.css';

const UserDashboard = () => {
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [betIndex, setBetIndex] = useState(0);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get('/api/users/today-games');
        setGames(response.data.games);
      } catch (error) {
        console.error('Erro ao carregar jogos:', error);
      }
    };
    fetchGames();
  }, []);

  const formatDate = (dateString) => {
    // Dividir a string ISO em data e hora
    const [date, time] = dateString.split('T');
    
    // Reorganizar a data para o formato dd/MM/yyyy
    const [year, month, day] = date.split('-');
    const formattedDate = `${day}/${month}/${year}`;
    
    // Retornar a data e hora no formato desejado
    return `${time.slice(0, 5)} ${formattedDate}`; // Exibe somente HH:mm
  };

  const openModal = (game) => {
    setSelectedGame(game);
    setBetIndex(0);
  };

  const closeModal = () => {
    setSelectedGame(null);
    setBetIndex(0);
  };

  const nextBet = () => {
    if (betIndex < selectedGame.bets.length - 1) {
      setBetIndex(betIndex + 1);
    }
  };

  const prevBet = () => {
    if (betIndex > 0) {
      setBetIndex(betIndex - 1);
    }
  };

  return (
    <div className={styles['user-dashboard']}>
      <Navbar />
      <div className={styles['games-table-container']}>
        <h2>Jogos de Hoje</h2>
        <table className={styles['games-table']}>
  <thead>
    <tr>
      <th>CONFRONTO</th>
      <th>ODD</th>
      <th>DATA E HORA</th>
    </tr>
  </thead>
  <tbody>
    {(games || []).map((game) => (
      <tr key={game._id} onClick={() => openModal(game)}>
        <td data-label="Confronto">
          <img src={game.teamA_badge} alt={game.teamA_name} width="40" />
          <span className="vs-separator"> x </span>
          <img src={game.teamB_badge} alt={game.teamB_name} width="40" />
        </td>
        <td data-label="ODD">{game.bets[0]?.odds || 'N/A'}</td>
        <td data-label="Data e Hora">{formatDate(game.matchDate)}</td>
      </tr>
    ))}
  </tbody>
</table>
      </div>

      <Modal
  isOpen={!!selectedGame}
  onRequestClose={closeModal}
  className={styles['game-modal']}
  overlayClassName={styles['modal-overlay']}
>
  {selectedGame && (
    <div className={styles['modal-content']}>
      <div className={styles['modal-header']}>
        <div className={styles['team-container']}>
          <img src={selectedGame.teamA_badge} alt={selectedGame.teamA_name} width="60" />
          <span className={styles['team-name']}>{selectedGame.teamA_name}</span>
        </div>

        <div className={styles['vs-container']}>
        <span className={styles['game-date']}>
        {formatDate(selectedGame.matchDate)}
        </span>
          <span className={styles['vs-separator-large']}> - </span>
        </div>

        <div className={styles['team-container']}>
          <img src={selectedGame.teamB_badge} alt={selectedGame.teamB_name} width="60" />
          <span className={styles['team-name']}>{selectedGame.teamB_name}</span>
        </div>
      </div>

      <div className={styles['modal-title-container']}>
        <h3>{selectedGame.bets[betIndex]?.title}</h3>
        <div className={styles['odd-container']}>
          <span className={styles['odd-label']}>ODD:</span>
          <span className={styles['odd']}>{selectedGame.bets[betIndex]?.odds}</span>
        </div>
      </div>

      <p className={styles['subtitle']}>{selectedGame.bets[betIndex]?.subtitle}</p>

          {/* Barra de Carregamento para Probabilidade */}
    <div className={styles['probabilidade-container']}>
      <label className={styles['probabilidade-label']}>Probabilidade de Acerto:</label>
      <div className={styles['progress-bar']}>
        <div
          className={styles['progress']}
          style={{ width: `${selectedGame.probabilidade || 0}%` }}
        >
          {selectedGame.probabilidade || 0}%
        </div>
      </div>
    </div>

      {selectedGame.matchLink && (
        <a href={selectedGame.matchLink} target="_blank" rel="noopener noreferrer">
          <button className={styles['game-link-btn']}>Link do Jogo</button>
        </a>
      )}

      <div className={styles['bet-navigation']}>
        {selectedGame?.bets.length > 1 && (
          <>
            <button className={styles['bet-navigation prev-button']} onClick={prevBet} disabled={betIndex === 0}>◄</button>
            <button className={styles['bet-navigation next-button']} onClick={nextBet} disabled={betIndex === selectedGame.bets.length - 1}>►</button>
          </>
        )}
      </div>
    </div>
  )}
</Modal>
<Footer />
    </div>
  );
};

export default UserDashboard;