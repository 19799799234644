import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';
import { UserContext } from '../components/contexts/UserContext';
import styles from '../styles/pages/Login.module.css';

Modal.setAppElement('#root');

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState('');
  const [licenseMessage, setLicenseMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const { setUser, setIsPaidUser, syncUserData, isPaidUser } = useContext(UserContext);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const res = await axios.post('/api/users/login', formData);

      if (res.data.token) {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('sessionToken', res.data.sessionToken);

        const daysRemaining = res.data.daysRemaining || 0;

        if (daysRemaining > 0) {
          setLicenseMessage(`Sua licença expira em ${daysRemaining} dias.`);
          setIsPaidUser(true);
        } else {
          setLicenseMessage('Sua licença expirou. Por favor, renove seu pagamento.');
          setIsPaidUser(false);
        }

        setUser(res.data.user);
        setIsModalOpen(true);

        // Atualizar dados do usuário no contexto
        syncUserData();
      }
    } catch (err) {
      if (err.response?.status === 403) {
        setError(err.response.data.msg || 'Erro ao fazer login.');
      } else {
        setError('E-mail ou senha incorreta.');
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (!isPaidUser) {
      navigate('/pagamento');
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <div className={styles['login-container']}>
      <form className={styles['login-form']} onSubmit={onSubmit}>
        <h2>LOGIN</h2>
        {error && <p className={styles['error-message']}>{error}</p>}
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={onChange}
          placeholder="Email"
          required
          className={styles['login-input']}
        />
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={onChange}
          placeholder="Senha"
          required
          className={styles['login-input']}
        />
        <button type="submit" className={styles['login-button']}>Entrar</button>
        <Link to="/redefinirSenha" className={styles['forgot-password']}>Esqueci minha senha</Link>
      </form>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Informações da Licença"
        overlayClassName={styles['modal-overlay']}
        className={styles['modal-content']}
      >
        <h2>Informações da Licença</h2>
        <p>{licenseMessage}</p>
        <button onClick={closeModal} className={styles['modal-button']}>Ok</button>
      </Modal>
    </div>
  );
};

export default Login;
